.App {
  text-align: center;
}

.App-logo {
  height: 20vh;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.corporate {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 25%;
  background: white;
}

.corporate .viewport {
  margin: auto;
}

.icp p {
  float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px;color:#939393;
}

.icp a {
  display:inline-block;text-decoration:none;height:20px;line-height:20px;
}

.icp img {
  float:left;
}